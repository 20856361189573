import React, { FunctionComponent } from "react"
import { useQuery } from "react-query"
import { Redirect } from "@reach/router"
import { getCurrentUser } from "@utils/auth"
import { Spinner } from "@components/spinner/spinner"

//TODO: I don't think we are using this login/logout page anymore. Perhaps we can remove this file?
const Login: FunctionComponent<any> = (props) => {
  const prevPath = props?.location?.state?.prevPath || "/"

  const { isLoading } = useQuery("login", () => getCurrentUser())

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    )
  }

  //TODO: handle error
  // if (error || !user)

  return <Redirect noThrow to={prevPath} from="/login" />
}

export default Login
